import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {users as usersService} from "../../services/api.service";
import {TextField} from '@mui/material';
import alert from "../../services/alert.service";

export default function UserForm (props) {

    const defaultData = {
        name: '',
        email: '',
        password: '',
        password_confirmation: ''
    };

    const [data, setData] = useState(defaultData);
    const [errors, setErrors] = useState({});

    const linkState = key => {
        return event => {
            setData(
                {
                    ...data,
                    ...{
                        [key]: (key !== 'file')? event.target.value: event.target.files[0]
                    }
                }
            );
        };
    };

    const onClose = () => {
        props.onFormClose();
    };

    const onSave = () => {
        let pSave;
        if(props.id === -1){
            pSave = usersService.store(data);
        } else {
            pSave = usersService.update(props.id, data);
        }
        pSave.then((res) => {
            alert.push('success', `User successfully ${props.id===-1? 'created' : 'saved'}!`);
        }).catch((err) => {
            setErrors(err.response.data);
            alert.push('error', 'Something went wrong!');
        })
        props.onFormSave( pSave );
    };

    useEffect(() => {
        if(props.id){
            if(props.id !== -1) {
                usersService.show(props.id)
                    .then((res) => {
                        setData(res.data);
                    });
            } else {
                setData(defaultData);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id]);

    return (
        <Dialog
            open={props.open}
            onClose={() => { onClose() }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>
                {
                    props.id === -1?
                        'Create new user'
                        : `Update user '${data.name}'`
                }
            </DialogTitle>
            <DialogContent>
                <TextField error={!!errors['name']} helperText={errors['name']||''} onChange={linkState('name')} value={data.name} margin="dense" autoFocus label="Name" type="text" fullWidth/>
                <TextField error={!!errors['email']} helperText={errors['email']||''} onChange={linkState('email')} value={data.email} margin="dense" autoFocus label="Email" type="text" fullWidth/>
                {(props.id === -1) &&
                    <React.Fragment>
                        <TextField error={!!errors['password']} helperText={errors['password']||''} onChange={linkState('password')} value={data.password} margin="dense" autoFocus label="Password" type="password" fullWidth/>
                        <TextField error={!!errors['password_confirmation']} helperText={errors['password_confirmation']||''} onChange={linkState('password_confirmation')} value={data.password_confirmation} margin="dense" autoFocus label="Repeat Password" type="password" fullWidth/>
                    </React.Fragment>
                }

            </DialogContent>
            <DialogActions>
                <Button onClick={() => { onSave() }} color="primary" autoFocus>
                    {props.id !== -1? 'Save': 'Create'}
                </Button>
                <Button onClick={() => { onClose() }} color="secondary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}