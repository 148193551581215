import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function DeleteConfirm (props) {

    const onCancel = () => {
        props.onCancel();
    };

    const onConfirm = () => {
        props.onConfirm();
    };

    return (
        <Dialog
            open={props.open}
            onClose={() => { onCancel() }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>{ 'Removal confirmation' }</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to remove this item?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { onCancel() }} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => { onConfirm() }} color="secondary" autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    )
}