import React from "react";
import { Card, CardContent, CardActions, Button, Box, FormControl, InputLabel, Input, FormHelperText, Grid } from "@mui/material";
import authService from '../../services/auth.service';
import { connect } from 'react-redux';
import history from "../../app/history";

class LoginView extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            email: 'abyss403@gmail.com',
            password: 'Password123',
            error: false
        };
        // Event handlers
        this.onEmailChange = this.onEmailChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onEmailChange(event){
        this.setState({email: event.target.value});
    }

    onPasswordChange(event){
        this.setState({password: event.target.value});
    }

    onSubmit(event){
        event.preventDefault();

        console.log('Login:' , this.state.email, this.state.password);
        authService.login(this.state.email, this.state.password)
            .then(() => {
                history.push('/videos');
            })
            .catch((err) => {
                this.setState({error: err.message});
            })
        ;
    }

    render() {
        return (
            <Grid justifyContent={"center"} container>
                <Grid item md={6} lg={4} xs={12}>
                    <Box mt={5}>
                        <Card width="100%">
                            <form onSubmit={this.onSubmit}>
                                <CardContent>
                                    <Grid container direction={"column"} spacing={2}>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth error={!!this.state.error}>
                                                <InputLabel>Email </InputLabel>
                                                <Input value={this.state.email} onChange={this.onEmailChange} />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth error={!!this.state.error}>
                                                <InputLabel>Password</InputLabel>
                                                <Input value={this.state.password} onChange={this.onPasswordChange} type={"password"} />
                                                { this.state.error &&
                                                    <FormHelperText id="my-helper-text">{this.state.error}</FormHelperText>
                                                }
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions>
                                    <Box justifyContent="center" width={"100%"}>
                                        <Button type="submit" color="primary">
                                            Login
                                        </Button>
                                    </Box>
                                </CardActions>
                            </form>
                        </Card>
                    </Box>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = function(state){
    return {
        access_token: state.auth.access_token
    }
};

export default connect(mapStateToProps)(LoginView);