import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Box,
    Radio,
    FormLabel,
    FormControl,
    RadioGroup,
    FormControlLabel,
    FormHelperText,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    IconButton,
    MenuItem,
    Select,
    InputLabel,
    CircularProgress
} from '@mui/material';
import {videos as videosService} from "../../services/api.service";
import {CloudUpload, ExpandMore as ExpandMoreIcon, Delete as DeleteIcon, Add as CreateIcon} from '@mui/icons-material';
import {Check} from '@mui/icons-material';
import alert from "../../services/alert.service";
import _ from 'lodash';
import uuid from 'uuid';
// Validation
import * as yup from "yup";
import parseYupErrors from "../../app/yup";

const schema = yup.object().shape({
    name: yup.string().label('Name').required(),
    presets: yup.array().of(
        yup.object().shape({
            name: yup.string().label('Preset Name').required(),
            stream_platform: yup.string().label('Platform').required(),
            stream_chunk_length: yup.number().min(3).max(20).label('Chunk Length').required(),
            fast_forward_length: yup.number().min(30).max(90).label('Fast Forward Length').required(),
            fast_forward_name: yup.string().label('Fast Forward Video').required(),
            sub_presets: yup.array().label('Sub Presets'),
            min_compression_with_ff: yup.number().min(0).max(100).label('Min Compression With FF').required(),
        })
    )
});

export default function VideoForm (props) {

    const defaultPreset = {
        name: '',
        min_compression_with_ff: 0,
        stream_platform: 'android',
        stream_chunk_length: '10',
        file_segments: '',
        file_settings: '',
        fast_forward_length: '30',
        fast_forward_name: 'default',
        sub_presets: [],
        uniqueId: ''
    };

    const defaultData = {
        name: '',
        presets: [ defaultPreset ]
    };

    const [data, setData] = useState(defaultData);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const linkState = key => {
        return event => {
            const value = (key.indexOf('file_') === -1)?
                event.target.value
                :event.target.files[0];
            const dataClone = _.cloneDeep(data);
            _.set(dataClone, key, value);
            setData(
                dataClone
            );
        };
    };

    const onClose = () => {
        setData(defaultData);
        setErrors({});
        props.onFormClose();
    };

    const [uploadProgress, setUploadProgress] = useState(null)

    const onUploadProgress = (progressEvent) => {
        const { loaded, total } = progressEvent;
        let uploadProgressPercent = Math.floor((loaded * 100 * 10) / total) / 10;
        setUploadProgress(uploadProgressPercent)
        if (uploadProgressPercent < 100) {
            console.log(`${loaded} bytes of ${total} bytes. ${uploadProgressPercent}%`);
        }
    };

    const onSave = () => {

        schema
            .validate(data, {abortEarly: false})
            .then(() => {
                // FE Validation passed, query API
                setLoading(true);
                let pSave;
                if(props.id === -1){
                    pSave = videosService.store(data, {onUploadProgress});
                } else {
                    pSave = videosService.update(props.id, data, {onUploadProgress});
                }
                pSave.then((res) => {
                    alert.push('success', `Video successfully ${props.id===-1? 'created' : 'saved'}!`);
                }).catch((err) => {
                    setErrors(err.response.data);
                    alert.push('error', 'Something went wrong!');
                }).finally(() => {
                    setLoading(false);
                });
                props.onFormSave( pSave );
            })
            .catch( err => setErrors(parseYupErrors(err)));
    };

    const onPresetDelete = (iPreset) => {
        const oldData = { ...data };
        oldData.presets.splice(iPreset, 1);
        setData(oldData);
    }

    const onPresetCreate = () => {
        const oldData = { ...data };
        defaultPreset.uniqueId = uuid.v4();
        oldData.presets.push(
            defaultPreset
        );
        setData(oldData);
    }

    const allowSubPreset = (preset, subPreset) => {
        //Check: if preset have a name
        if (!subPreset.name) {
            return;
        }

        //Check: the exists preset should not add itself
        if (preset._id && preset._id === subPreset._id) {
            return;
        }

        //Check: the new preset should not add itself
        return (!preset.uniqueId || !subPreset.uniqueId || preset.uniqueId !== subPreset.uniqueId);
    }

    useEffect(function (){
        if(props.id){
            if(props.id !== -1) {
                videosService.show(props.id)
                    .then((res) => {
                        setData(res.data);
                    });
            } else {
                setData(defaultData);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id]);

    return (
        <Dialog
            open={props.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={'md'}
            disableEscapeKeyDown={true}>
            <DialogTitle>
                {
                    props.id === -1?
                        'Create new video'
                        : `Update video '${data.name}'`
                }
            </DialogTitle>
            <DialogContent style={{width:600}}>

                <TextField
                    disabled={loading}
                    error={!!errors['name']}
                    helperText={errors['name']||''}
                    onChange={linkState('name')}
                    value={data.name}
                    margin="dense"
                    autoFocus
                    label="Name"
                    type="text"
                    fullWidth
                />

                <Box mt={2}>
                    <input id={'video-ios-file'}
                           onChange={linkState('file_video_ios')}
                           style={{ display: 'none' }}
                           type="file"
                           accept=".ts"
                    />
                    <label htmlFor="video-ios-file">
                        <Button
                            disabled={loading}
                            variant="contained"
                            component="span"
                            color="primary"
                            startIcon={<CloudUpload />}
                        >
                            Upload Video IOS
                            { (data.file_video_ios || data.video_ios_file) &&
                            <React.Fragment>
                                &emsp;<Check/>
                            </React.Fragment>
                            }
                        </Button>
                        { data.video_ios_file &&
                        <React.Fragment>
                            &emsp;{data.file_video_ios?
                                data.file_video_ios.name
                                : data.video_ios_file.originalname }
                        </React.Fragment>
                        }
                    </label>
                </Box>

                <Box my={2}>
                    <input id={'video-android-file'}
                           onChange={linkState(`file_video_android`)}
                           style={{ display: 'none' }}
                           type="file"
                           accept=".ts"
                    />
                    <label htmlFor="video-android-file">
                        <Button
                            disabled={loading}
                            variant="contained"
                            component="span"
                            color="primary"
                            startIcon={<CloudUpload />}
                        >
                            Upload Video Android
                            { (data.file_video_android || data.video_android_file) &&
                            <React.Fragment>
                                &emsp;<Check/>
                            </React.Fragment>
                            }
                        </Button>
                        { data.video_android_file &&
                        <React.Fragment>
                            &emsp;{data.file_video_android?
                                data.file_video_android.name
                                : data.video_android_file.originalname }
                        </React.Fragment>
                        }
                    </label>
                </Box>

                {data.presets.map((preset, iPreset) =>
                    <React.Fragment key={`preset-${iPreset}`}>
                        <Box mt={1}>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>

                                    <Box display={'flex'} alignItems={'center'} flexGrow={'1'}>
                                        <Typography>
                                            {
                                                !preset.name
                                                    ? 'New Preset'
                                                    : `Preset: ${preset.name}`
                                            }
                                        </Typography>
                                    </Box>

                                    <IconButton
                                        disabled={loading || data.presets.length < 2}
                                        aria-label="delete"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            onPresetDelete(iPreset)
                                        }}
                                        size="large">
                                        <DeleteIcon />
                                    </IconButton>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box flexDirection={'column'} flexGrow={'1'}>

                                        <TextField
                                            disabled={loading}
                                            error={_.get(errors, `presets[${iPreset}].name`, false)}
                                            helperText={_.get(errors, `presets[${iPreset}].name`, '')}
                                            onChange={linkState(`presets[${iPreset}].name`)}
                                            value={preset.name}
                                            margin="dense"
                                            label="Name"
                                            type="text"
                                            fullWidth
                                        />

                                        <TextField
                                            disabled={loading}
                                            error={_.get(errors, `presets[${iPreset}].min_compression_with_ff`, false)}
                                            helperText={_.get(errors, `presets[${iPreset}].min_compression_with_ff`, '')}
                                            onChange={linkState(`presets[${iPreset}].min_compression_with_ff`)}
                                            value={preset.min_compression_with_ff}
                                            margin="dense"
                                            label="Min Compression With FF"
                                            min={0}
                                            max={100}
                                            type="number"
                                            fullWidth
                                        />

                                        <TextField
                                            disabled={loading}
                                            error={_.get(errors, `presets[${iPreset}].stream_chunk_length`, false)}
                                            helperText={_.get(errors, `presets[${iPreset}].stream_chunk_length`, '')}
                                            onChange={linkState(`presets[${iPreset}].stream_chunk_length`)}
                                            value={preset.stream_chunk_length}
                                            margin="dense"
                                            label="Chunk Length (sec)"
                                            type="number"
                                            fullWidth
                                        />

                                        <Box mt={2}>
                                            <FormControl
                                                component="fieldset"
                                                error={!!_.get(errors, `presets[${iPreset}].stream_platform`, false)}
                                                margin="dense"
                                                fullWidth
                                            >
                                                <FormLabel component="legend" >Platform</FormLabel>
                                                <RadioGroup
                                                    disabled={loading}
                                                    value={preset.stream_platform}
                                                    onChange={linkState(`presets[${iPreset}].stream_platform`)}
                                                    row
                                                >
                                                    <FormControlLabel disabled={loading} value="ios" control={<Radio />} label="IOS" />
                                                    <FormControlLabel disabled={loading} value="android" control={<Radio />} label="Android" />
                                                </RadioGroup>
                                                { _.get(errors, `presets[${iPreset}].stream_platform`, false) &&
                                                <FormHelperText>
                                                    {_.get(errors, `presets[${iPreset}].stream_platform`, '')}
                                                </FormHelperText>
                                                }
                                            </FormControl>
                                        </Box>

                                        <Box mt={2}>
                                            <input id={`segments-file-${iPreset}`} onChange={linkState(`presets[${iPreset}].file_segments`)} style={{ display: 'none' }} type="file" accept=".json"/>
                                            <label htmlFor={`segments-file-${iPreset}`}>
                                                <Button disabled={loading} variant="contained" component="span" color="primary" startIcon={<CloudUpload />}>
                                                    Upload Segments
                                                    { (preset.file_segments || preset.segments_file) && (
                                                        <React.Fragment>
                                                            &emsp;<Check/>
                                                        </React.Fragment>
                                                    )}
                                                </Button>
                                                { preset.segments_file && (
                                                    <React.Fragment>
                                                        &emsp;{preset.file_segments? preset.file_segments.name : preset.segments_file.originalname }
                                                    </React.Fragment>
                                                )}
                                            </label>
                                        </Box>

                                        <Box mt={2}>
                                            <input id={`settings-file-${iPreset}`} onChange={linkState(`presets[${iPreset}].file_settings`)} style={{ display: 'none' }} type="file" accept="image/*"/>
                                            <label htmlFor={`settings-file-${iPreset}`}>
                                                <Button disabled={loading} variant="contained" component="span" color="primary" startIcon={<CloudUpload />}>
                                                    Settings Snapshot
                                                    { (preset.file_settings || preset.settings_file) && (
                                                        <React.Fragment>
                                                            &emsp;<Check/>
                                                        </React.Fragment>
                                                    )}
                                                </Button>
                                                { preset.settings_file && (
                                                    <React.Fragment>
                                                        &emsp;{preset.file_settings? preset.file_settings.name : preset.settings_file.originalname }
                                                    </React.Fragment>
                                                )}
                                            </label>
                                        </Box>

                                        <Box mt={3}>
                                            <TextField
                                                disabled={loading}
                                                error={_.get(errors, `presets[${iPreset}].fast_forward_length`, false)}
                                                helperText={_.get(errors, `presets[${iPreset}].fast_forward_length`, '')}
                                                onChange={linkState(`presets[${iPreset}].fast_forward_length`)}
                                                value={preset.fast_forward_length}
                                                label="Fast Forward Length (frames)"
                                                type="number"
                                                fullWidth
                                            />
                                        </Box>
                                        <Box mt={2}>
                                            <FormControl fullWidth>
                                                <InputLabel>Fast Forward Type</InputLabel>
                                                <Select
                                                    label="Fast Forward Type"
                                                    disabled={loading}
                                                    value={preset.fast_forward_name}
                                                    onChange={linkState(`presets[${iPreset}].fast_forward_name`)}
                                                >
                                                    <MenuItem value={'default'}>Default</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <Box mt={2}>
                                            <FormControl fullWidth>
                                                <InputLabel>Sub Presets</InputLabel>
                                                <Select
                                                    disabled={loading}
                                                    multiple
                                                    value={preset.sub_presets || []}
                                                    onChange={linkState(`presets[${iPreset}].sub_presets`)}
                                                >
                                                    {data.presets.map((subPreset, iSubPreset) => (
                                                        allowSubPreset(preset, subPreset) &&
                                                        <MenuItem key={iSubPreset} value={subPreset._id || subPreset.uniqueId }>
                                                            {subPreset.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </React.Fragment>
                )}

                <IconButton
                    aria-label="create"
                    onClick={(event) => {
                        onPresetCreate()
                    }}
                    size="large">
                    <CreateIcon />
                </IconButton>

            </DialogContent>
            <DialogActions>
                {
                    loading &&
                        <Box display={'flex'} alignItems={'center'}>
                            <Box display={'flex'} mr={1}>
                                { uploadProgress }%
                            </Box>
                            <Box display={'flex'}>
                                <CircularProgress size={20} />
                            </Box>
                        </Box>
                }
                <Button disabled={loading} onClick={() => { onSave() }} color="primary" autoFocus>
                    {props.id !== -1? 'Save': 'Create'}
                </Button>
                <Button disabled={loading} onClick={() => { onClose() }} color="secondary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
