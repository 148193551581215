import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ReactHlsPlayer from 'react-hls-player';
import {TextField} from "@mui/material";
import config from "../app/config";

export default function Player (props) {

    const onClose = () => {
        props.onClose();
    };

    const [thld, setThld] = useState('1');
    const [hlsUrl, setHlsUrl] = useState(null);

    useEffect(() => {
        // Update the document title using the browser API
        setHlsUrl(`${config.api}hls/process?preset_id=${props.id}&thld=${thld}&ff_thld=6`);
    }, [props.id, thld]);

    return (
        <Dialog
            open={props.open}
            onClose={() => { onClose() }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>{ 'Player' }</DialogTitle>
            <DialogContent>
                <TextField onChange={(e) => {setThld(e.target.value)}} value={thld} margin="dense" autoFocus label="Threshold" type="text" fullWidth/>
                <ReactHlsPlayer
                    url={hlsUrl}
                    //url={'https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8'}
                    autoplay={false}
                    controls={true}
                    width={'100%'}
                    height={'auto'}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { onClose() }} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}