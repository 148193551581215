import React from "react";
import { connect } from 'react-redux'
import { Alert, AlertTitle, Box } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import alert from "../../services/alert.service";

const LayoutAlert = (props) => {

    const onCloseAll = () => {
        alert.flush();
    }

    const onCloseAlert = (index) => {
        alert.close(index);
    }

    if (!props.alerts.length) {
        return null;
    }

    return (
        <Snackbar
            open={props.alerts.length > 0}
            autoHideDuration={3000}
            onClose={onCloseAll}
            anchorOrigin={{vertical:'top', horizontal:'right'}}
        >
            <Box>
                {
                    (props.alerts || []).map((alert, i) => {
                        const variant = alert.variant||'success';
                        const title = variant.charAt(0).toUpperCase() + variant.slice(1);
                        const message = alert.message || '';
                        return (
                            <Alert key={i} severity={variant} onClose={ () => { onCloseAlert(i) } }>
                                <AlertTitle>{title}</AlertTitle>
                                {message}
                            </Alert>
                        );
                    })
                }
            </Box>
        </Snackbar>
    );
}

const mapStateToProps = function(state){
    return {
        alerts: state.alert.list
    }
};

export default connect(mapStateToProps)(LayoutAlert);