export function toFormData(obj, form, namespace = []) {

    let formData = form || new FormData();
    let formKey;

    for(let property in obj) {

        if(obj.hasOwnProperty(property)) {
            if(namespace.length) {
                const namespaceString = namespace.reduce((acc, v, i) => {
                    acc += !!i? `[${v}]`: v;
                    return acc;
                }, '')
                formKey = namespaceString + '[' + property + ']';
            } else {
                formKey = property;
            }
            if(typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
                toFormData(obj[property], formData, [...namespace, property]);
            } else {
                formData.append(formKey, obj[property]);
            }
        }
    }
    return formData;
}