const auth = (state = {
    access_token: localStorage.getItem('access_token'),
    user: JSON.parse(localStorage.getItem('user'))
}, action) => {

    switch (action.type) {
        case 'STORE_JWT_TOKEN':
            return { ...state, access_token: action.access_token };
        case 'STORE_USER':
            return { ...state, user: action.user };
        default:
            return state
    }
};

export default auth
