import React, {useState} from "react";
import { styled } from '@mui/material/styles';
import {Box, IconButton, makeStyles, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";

const PREFIX = 'session';

const classes = {
    subTableCell: `${PREFIX}-subTableCell`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('tbody')(() => ({
    [`& .${classes.subTableCell}`]: {
        borderBottom: 'none'
    }
}));

export default function SessionView({session}) {



    const [expanded, setExpanded] = useState(false)

    return (
        <Root>
            <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell align="right">{session.ip}</TableCell>
                <TableCell align="right">{session.device}</TableCell>
                <TableCell align="right">{session.compression}%</TableCell>
                <TableCell component="th" scope="row">
                    <b>{session.video_name}</b>
                    <br/>
                    {session.preset_name}
                </TableCell>
                <TableCell align="right">{session.startedAtLocal}</TableCell>
                <TableCell align="right">{session.is_finished? session.finishedAtLocal: 'alive'}</TableCell>
                <TableCell align="right">{session.duration}</TableCell>
                <TableCell align="right">{Math.round(session.duration/60 * 100)/100}</TableCell>
                <TableCell align="right">{Math.round(session.total_duration/60 * 100)/100}</TableCell>
                <TableCell align="right">
                    <Box display={'flex'}>
                        <IconButton
                            onClick={() => {
                                setExpanded(!expanded)
                            }}
                            size="large">
                            {!expanded && <ExpandMore/>}
                            {!!expanded && <ExpandLess/>}
                        </IconButton>
                    </Box>
                </TableCell>
            </TableRow>
            { expanded && (
                <TableRow>
                    <TableCell colSpan={8} style={{padding: '5px'}}>
                        <Box
                            display={'flex'}
                            flexGrow={1}
                            style={{
                                background: '#f5f5f5',
                                borderRadius: '10px'
                            }}
                        >
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>Compression</TableCell>
                                        <TableCell>Start Time</TableCell>
                                        <TableCell>View(sec)</TableCell>
                                        <TableCell>View(min)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        session.steps.map(log => (
                                            <TableRow key={log.id}>
                                                <TableCell className={classes.subTableCell}>
                                                    {session.preset_name !== log.preset_name ? `> ${log.preset_name}`: ''}
                                                </TableCell>
                                                <TableCell className={classes.subTableCell}>{log.compression}%</TableCell>
                                                <TableCell className={classes.subTableCell}>{log.createdAtLocal}</TableCell>
                                                <TableCell className={classes.subTableCell}>{log.duration}</TableCell>
                                                <TableCell className={classes.subTableCell}>
                                                    {Math.round(log.duration/60 * 100)/100}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </Box>
                    </TableCell>
                </TableRow>
            )}
        </Root>
    );
}