import React, {useMemo} from "react";
import {Box, Icon, IconButton, Tooltip, Typography, Grid} from "@mui/material";
import {Check, Settings, Warning, SyncDisabled, ContentCopy} from "@mui/icons-material";

import config from "../../app/config";
import CircularProgressWithLabel from "../../components/CircularProgressWithLabel";
import useMediaQuery from "@mui/material/useMediaQuery";
import {isIOS} from 'react-device-detect';
import apiService from "../../services/api.service";
import alert from "../../services/alert.service";

export default function PresetView({ preset, setPreset, setPlayer, setSettingsOpenID }) {

    const mediaUpMd = useMediaQuery(theme => theme.breakpoints.up('md'))

    const playerUrl = useMemo(() => {
        const params = {}
        let url = `${config.player}/${preset._id}`
        if (preset.token) {
            params.token = preset.token
        }
        if (Object.values(params).length) {
            url += `?${new URLSearchParams(params)}`
        }
        return url
    },
    [
        config.player,
        preset
    ])

    const onRefreshToken = async () => {
        const res = await apiService.videos.refreshPresetToken(preset._id)
        setPreset(res.data)
        alert.push('success', 'Preset link successfully generated!')
    }

    const onCopyUrl = async () => {
        navigator.clipboard.writeText(playerUrl)
        alert.push('success', 'Preset link copied to clipboard!')
    }

    const controls = useMemo(() => (
        <React.Fragment>
            {preset.stream_status === 'pending' && (
                <Box display={'flex'}>
                    <Tooltip title={`Pending: ${preset.stream_progress}%`}>
                        <Box>
                            <CircularProgressWithLabel value={parseInt(preset.stream_progress)} />
                        </Box>
                    </Tooltip>
                </Box>
            )}
            {preset.stream_status === 'done' && (
                <React.Fragment>
                    <IconButton size={'small'}>
                        <Tooltip title={"Preset stream done"}>
                            <Check color={"primary"}/>
                        </Tooltip>
                    </IconButton>

                    <IconButton size={'small'} onClick={onRefreshToken}>
                        <Tooltip title={"Generate new link"}>
                            <SyncDisabled color={"primary"}/>
                        </Tooltip>
                    </IconButton>

                    <IconButton size={'small'} onClick={onCopyUrl}>
                        <Tooltip title={"Copy player url"}>
                            <ContentCopy color={"primary"}/>
                        </Tooltip>
                    </IconButton>

                    <IconButton size={'small'}>
                        { (preset.stream_platform === 'ios' && !isIOS) || (preset.stream_platform !== 'ios' && isIOS)
                            ? (
                                <Tooltip title={"Your platform is not compatible with this movie"}>
                                    <Icon color="disabled">
                                        movie
                                    </Icon>
                                </Tooltip>
                            )
                            : (
                                <Tooltip title={"Play"}>
                                    <a target="_blank" href={playerUrl} rel="noopener noreferrer">
                                        <Icon
                                            color="primary"
                                            // onClick={() => { setPlayer(preset._id) }}
                                        >
                                            movie
                                        </Icon>
                                    </a>
                                </Tooltip>
                            )
                        }
                    </IconButton>
                </React.Fragment>
            )}
            {preset.stream_status === 'failed' && (
                <IconButton size={'small'}>
                    <Tooltip title={preset.stream_message}>
                        <Warning color="secondary"/>
                    </Tooltip>
                </IconButton>
            )}
            {preset?.settings_file && (
                <IconButton size={'small'}>
                    <Tooltip title={'Segment Snapshot'}>
                        <Settings color="primary" onClick={() => setSettingsOpenID(preset._id)}/>
                    </Tooltip>
                </IconButton>
            )}
        </React.Fragment>
    ), [preset, mediaUpMd])

    return (
        <Grid container>
            <Grid item md={6}>
                <Box display={'flex'} flexDirection={'column'} mt={1}>
                    { preset.name &&
                        <Box
                            display={'flex'}
                            flexDirection={'row'}
                            alignItems={'center'}
                            flexWrap="wrap"
                        >
                            <Box display={'flex'} mr={1}>
                                <Typography align={'left'}>
                                    <b>{ preset.name }</b>
                                </Typography>
                            </Box>
                            <Box display={'flex'}>
                                { controls }
                            </Box>
                        </Box>
                    }
                    <Box display={'flex'}>
                        <Typography variant={'caption'} noWrap>
                            <b><i>Stream:</i></b>
                            &emsp;
                            { preset.stream_platform}, {preset.stream_chunk_length } sec(s)
                        </Typography>
                    </Box>
                    <Box display={'flex'}>
                        <Typography variant={'caption'} noWrap>
                            <b><i>FF:</i></b>
                            &emsp;
                            {preset.fast_forward_name}, {preset.fast_forward_length} frame(s)
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item md={6}>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    width={'100%'}
                >
                    {preset.subPresetsList?.map(subPreset => (
                        <Box style={{ textAlign: 'left' }} key={subPreset._id}>
                            <Typography variant={'caption'}>
                                <b>{ subPreset.name }</b>
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Grid>
        </Grid>
    );
}
