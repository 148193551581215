import { createTheme, adaptV4Theme } from '@mui/material/styles';

const index = createTheme(adaptV4Theme({
    overrides: {
        MuiAccordionSummary: {
            root: {
                backgroundColor: '#F6F6F6'
            }
        }
    }
}));

export default index;