import React from 'react';
import LayoutHeader from './components/layout/Header';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { Router, Switch, Route } from "react-router-dom";
import routes from "./app/routes/index";
import history from './app/history';
import ProtectedRoute from "./components/router/ProtectedRoute";
import authService from "./services/auth.service";
import { Redirect } from "react-router-dom";
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import index from './app/theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

function App() {
  return (
      <div className="App">
          <StyledEngineProvider injectFirst>
              <ThemeProvider theme={index}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Router history={history}>
                            <LayoutHeader/>
                            <CssBaseline/>
                            <Container style={{
                                maxWidth: "95%"
                            }}>
                                <Switch>
                                    {routes.map((route, i) => (
                                        (route.guest)
                                            ? <Route
                                                path={route.path}
                                                key={route.path}
                                                component={route.component}
                                            />
                                            : <ProtectedRoute
                                                path={route.path}
                                                key={route.path}
                                                component={route.component}
                                            />
                                    ))}
                                    <Route
                                        exact
                                        path="/"
                                        render={() => {
                                            return (
                                                !authService.isGuest() ?
                                                    <Redirect to="/videos" /> :
                                                    <Redirect to="/login" />
                                            )
                                        }}
                                    />
                                </Switch>
                            </Container>
                      </Router>
                  </LocalizationProvider>
              </ThemeProvider>
          </StyledEngineProvider>
      </div>
  );
}

export default App;
