import axios from '../app/axios';
import {toFormData} from '../app/utils/formData';

export const users = {
    index: async () => {
        return axios.get('users');
    },
    store: async (data) => {
        return axios.post('users', data);
    },
    update: async (id, data) => {
        return axios.put(`users/${id}`, data);
    },
    show: async (id) => {
        return axios.get(`users/${id}`);
    },
    destroy: async (id) => {
        return axios.delete(`users/${id}`);
    }
};

export const videos = {
    index: async () => {
        return axios.get('videos');
    },
    store: async (data, config = {}) => {
        return axios.post('videos', toFormData(data), config);
    },
    update: async (id, data, config = {}) => {
        return axios.put(`videos/${id}`, toFormData(data), config);
    },
    show: async (id) => {
        return axios.get(`videos/${id}`);
    },
    destroy: async (id) => {
        return axios.delete(`videos/${id}`);
    },
    // Stream processing
    streamStart: async (id) => {
        return axios.post(`videos/stream/start/${id}`);
    },
    streamStop: async (id) => {
        return axios.post(`videos/stream/stop/${id}`);
    },
    // Preset
    refreshPresetToken: async (presetId) => {
        return axios.post(`videos/presets/refresh-preset-token/${presetId}`)
    }
};

export const tests = {
    index: async () => {
        return axios.get('tests');
    },
    store: async (data) => {
        return axios.post('tests', toFormData(data));
    },
    update: async (id, data) => {
        return axios.put(`tests/${id}`, toFormData(data));
    },
    show: async (id) => {
        return axios.get(`tests/${id}`);
    },
    destroy: async (id) => {
        return axios.delete(`tests/${id}`);
    },
    process: async (id) => {
        return axios.post(`tests/process/${id}`);
    }
};

export const logs = {
    index: async (params) => {
        return axios.get('logs', { params });
    }
}

export default {
    users,
    videos,
    tests,
    logs
}