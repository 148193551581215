import React, {useEffect, useState} from "react";
import {Box, FormControlLabel, FormGroup, Switch, Typography} from "@mui/material";

export default function DebugConfigView() {

    const [playerDebugMode, setPlayerDebugMode] = useState(!!localStorage.getItem('playerDebugMode'))

    return (
        <Box display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} mt={3}>
                <Typography variant={'h3'}>
                    Debug config
                </Typography>
            </Box>
            <Box display={'flex'} mt={3}>
                <FormGroup>
                    <FormControlLabel control={
                        <Switch
                            checked={playerDebugMode}
                            onChange={event => {
                                setPlayerDebugMode(!!event.target.checked)
                                localStorage.setItem('playerDebugMode', !!event.target.checked? '1': '0')
                            }}
                        />
                    } label="Disable player controls appearance" />
                </FormGroup>
            </Box>
        </Box>
    )
}