const storeJWTToken = access_token => ({
    type: 'STORE_JWT_TOKEN',
    access_token: access_token
});
const storeUser = user => ({
    type: 'STORE_USER',
    user: user
});

export default {
    storeJWTToken,
    storeUser
}