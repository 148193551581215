import React from "react";
import { Redirect } from "react-router-dom";
import authService from '../../services/auth.service';

class ProtectedRoute extends React.Component {

    render() {
        const Component = this.props.component;
        return !authService.isGuest() ? (
            <Component />
        ) : (
            <Redirect to={{ pathname: '/login' }} />
        );
    }
}

export default ProtectedRoute;