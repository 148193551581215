import LoginView from "../../views/auth/login.view";

const routes = [
    {
        path: "/login",
        component: LoginView,
        guest: true
    }
];

export default routes;