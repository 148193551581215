const alert = (state = {
    list: []
}, action) => {

    switch (action.type) {
        case 'PUSH_ALERT':
            return {
                ...state,
                list: [
                    ...state.list,
                    ...[
                        {
                            variant: action.variant,
                            message: action.message
                        }
                    ]
                ]
            };
        case 'CLOSE_ALERT':
            let list = [ ...state.list ];
            list.splice(action.index, 1);
            return {
                ...state,
                list: list
            };
        case 'FLUSH_ALERTS':
            return {
                ...state,
                list: []
            };
        default:
            return state
    }
};

export default alert;
