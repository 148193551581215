import React, {useState, useEffect, useCallback} from "react";
import { videos as videosService } from '../../services/api.service';
import alert from "../../services/alert.service";
import {
    Box,
    Button,
    ButtonGroup,
    Grid,
    Icon,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Tooltip,
    Dialog,
    DialogContent,
    DialogActions,
    Divider
} from "@mui/material";
import DeleteConfirm from "../../components/DeleteConfirm";
import Player from "../../components/Player";
import VideoForm from "./video.form";
import { sizeToMb } from '../../app/utils/fileUtil';
import socket from "../../app/socket";
import PresetView from "./preset.view";
import { grey } from '@mui/material/colors';
import config from "../../app/config";
import useMediaQuery from '@mui/material/useMediaQuery';

export default function VideosView(props) {
    
    const [data, setData] = useState([])
    const [pendingDeleteID, setPendingDeleteID] = useState(null)
    const [pendingFormID, setPendingFormID] = useState(null)
    const [playerID, setPlayerID] = useState(null)
    const [settingsOpenID, setSettingsOpenID] = useState(null)
    
    const mediaUpMd = useMediaQuery(theme => theme.breakpoints.up('md'))

    const prepareSubPresets = itemData => {
        return itemData.presets?.forEach(preset => {
            if (preset.sub_presets.length) {
                preset.subPresetsList = itemData.presets.filter(item => preset.sub_presets.includes(item._id))
            }
        })
    }

    const fetchItems = () => {
        videosService.index()
            .then(({data}) => {
                setData(data.map(row => {
                    prepareSubPresets(row)
                    return {id: row['_id'], ...row}
                }));
            });
    }

    const onPlayerClose = () => {
        setPlayerID(null)
    }

    const [socketMessage, setSocketMessage] = useState()

    const updateDataItemByID = message => {
        const id = message.id
        const itemData = message.data
        prepareSubPresets(itemData)

        setSocketMessage(null)
        console.log('SocketIO:', data, id, itemData)

        const index = data.findIndex(item => item._id === id);
        let newData = [ ...data ];
        newData[index] = {
            ...newData[index],
            ...itemData
        };
        setData(newData);
    }

    const updatePresetDataById = presetId => (presetData) => {
        let newData = data.map( video => (
            {
                ...video,
                presets: video.presets.map(preset =>
                    preset._id === presetId
                        ? presetData
                        : preset
                )
            }
        ))
        setData(newData);
    }

    useEffect(() => {
        if(socketMessage) {
            updateDataItemByID(socketMessage)
        }
    }, [socketMessage, updateDataItemByID])

    useState(() => {
        fetchItems()
        // Listed websocket updates from chunk generator webhooks
        socket.on("video-update", message => setSocketMessage(message))
    }, [], () => {
        socket.off("video-update")
    })

    const actionsControlsArr = (item) => {
        const hasPending = !!item.presets.find(preset => preset.stream_status === 'pending')
        return [
            <Button key={`item-${item._id}-update`} size={'small'} disabled={hasPending} onClick={() => setPendingFormID(item._id)}>
                <Tooltip title={"Update"}>
                    <Box>
                        {!hasPending && <Icon color="primary">edit</Icon>}
                        {hasPending && <Icon style={{ color: grey[500] }}>edit</Icon>}
                    </Box>
                </Tooltip>
            </Button>,
            <Button key={`item-${item._id}-delete`} size={'small'} disabled={hasPending} onClick={() => setPendingDeleteID(item._id)}>
                <Tooltip title={"Delete"}>
                    <Box>
                        {!hasPending && <Icon color="primary">delete</Icon>}
                        {hasPending && <Icon style={{ color: grey[500] }}>delete</Icon>}
                    </Box>
                </Tooltip>
            </Button>
        ]
    }

    const actionsColumn = (item) => {
        return (
            <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
                { actionsControlsArr(item).map( control => control)}
            </ButtonGroup>
        )
    }

    // const onStreamStart = (id) => {
    //     videosService.streamStart(id)
    //         .then((res) => {
    //             alert.push('success', 'Video stream processing started!');
    //             fetchItems();
    //         });
    // }
    //
    // const onStreamStop = (id) => {
    //     videosService.streamStop(id)
    //         .then((res) => {
    //             alert.push('success', 'Video stream processing stopped!');
    //             fetchItems();
    //         });
    // }

    const onDelete = () => {
        return videosService.destroy(pendingDeleteID)
            .then((res) => {
                alert.push('success', 'Video successfully deleted!')
                fetchItems()
                setPendingDeleteID(null)
            })
    }

    const onFormClose = () => {
        setPendingFormID(null)
    }

    const onFormSave = (pSave) => {
        pSave.then((res) => {
            setPendingFormID(null)
            fetchItems()
        })
    }

    const getSettingsOpenUrl = () => {
        let preset = null
        data.forEach(video => {
            video.presets.forEach(p => {
                if(p._id===settingsOpenID) {
                    preset = p
                }
            })
        })
        if (preset){
            return `${config.api_host}/${preset.settings_file.filename}`
        }
        return null
    }

    useEffect(()=>{
        console.log('Data changed -> ', data)
    }, [data])

    return (
        <React.Fragment>

            <DeleteConfirm open={!!pendingDeleteID} onCancel={()=>setPendingDeleteID(null)} onConfirm={onDelete}/>
            <VideoForm open={pendingFormID !== null} id={pendingFormID} onFormClose={onFormClose} onFormSave={onFormSave}/>
            <Player open={playerID !== null} id={playerID} onClose={onPlayerClose}/>

            <Dialog
                open={Boolean(settingsOpenID)}
                onClose={() => setSettingsOpenID(null)}
                aria-labelledby="settings-dialog-title"
                aria-describedby="settings-dialog-description"
                maxWidth={'xl'}
            >
                <DialogContent>
                    <img src={getSettingsOpenUrl()}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setSettingsOpenID(null)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Grid item xs={12}>
                <Box align="left">
                    <Box my={3}>
                        <Typography variant="h4" component="h2">
                            Videos
                        </Typography>
                    </Box>
                    <Box my={3}>
                        <Button color="primary" onClick={() => setPendingFormID(-1) }>Create</Button>
                    </Box>
                    <Box height={400} width={'100%'} align={'left'}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        { mediaUpMd &&
                                            <TableCell>Name</TableCell>
                                        }
                                        <TableCell>Video</TableCell>
                                        <TableCell><Box ml={4}>Presets</Box></TableCell>
                                        <TableCell><Box>Sub Presets</Box></TableCell>
                                        {mediaUpMd &&
                                            <TableCell align={"center"}>Actions</TableCell>
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((row) => (
                                        <TableRow key={row._id}>
                                            { mediaUpMd &&
                                                <TableCell>
                                                    {row.name}
                                                </TableCell>
                                            }
                                            <TableCell>
                                                <Box flexDirection={'column'}>
                                                    <Box display={{xs: 'flex', md: 'none'}} alignItems={'center'}>
                                                        <Box display={'flex'}>
                                                            <b>{row.name}</b>
                                                        </Box>
                                                        {!mediaUpMd &&
                                                            <Box display={'flex'} ml={1}>
                                                                {actionsControlsArr(row).map(control => control)}
                                                            </Box>
                                                        }
                                                    </Box>
                                                    { row.video_android_file &&
                                                        <Box>
                                                            <Box><b>Android:</b></Box>
                                                            <Box>
                                                                {row.video_android_file.originalname}
                                                                &nbsp;({sizeToMb(row.video_android_file)})
                                                            </Box>
                                                        </Box>
                                                    }
                                                    { row.video_ios_file &&
                                                        <Box>
                                                            <Box><b>IOS:</b></Box>
                                                            <Box>
                                                                { row.video_ios_file.originalname }
                                                                &nbsp;({ sizeToMb(row.video_ios_file)})
                                                            </Box>
                                                        </Box>
                                                    }
                                                </Box>
                                            </TableCell>
                                            <TableCell align={"center"} width={'50%'} colSpan={2}>
                                                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start">
                                                    <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                                                        {row.presets && row.presets.map((preset, iPreset) => (
                                                            <React.Fragment key={preset._id}>
                                                                { !!iPreset && (
                                                                    <Box my={2}>
                                                                        <Divider my={2}/>
                                                                    </Box>
                                                                )}
                                                                <PresetView
                                                                    setSettingsOpenID={setSettingsOpenID}
                                                                    preset={preset}
                                                                    setPreset={updatePresetDataById(preset._id)}
                                                                    setPlayerID={ preset_id => setPlayerID(preset_id) }
                                                                />
                                                            </React.Fragment>
                                                        ))}
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                            {mediaUpMd &&
                                                <TableCell align={"center"}>
                                                    {actionsColumn(row)}
                                                </TableCell>
                                            }
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </Grid>
        </React.Fragment>
    )
}
