import { auth as authActions } from '../app/store/actions';
import axios from '../app/axios';
import store from '../app/store';

class AuthService {

    constructor(){
        this._access_token = null;
        this._user = null;
    }

    logout(){
        this.access_token = null;
        this.user = null;
    }

    async login(email, password){
        try {
            const response = await axios.post('auth/login', {
                email: email,
                password: password
            });
            const data = response.data;

            if(data['access_token']){
                this.access_token = data['access_token'];
                this.user = (await axios.get('auth/me'))['data'];
            }
        } catch (e) {
            throw Error('Invalid email or password.');
        }
    }

    get access_token(){
        this._access_token = store.getState().auth.access_token;
        if(!this._access_token){
            this._access_token = localStorage.getItem('access_token');
        }
        return this._access_token;
    }

    set access_token(value){
        this._access_token = value;
        store.dispatch(authActions.storeJWTToken(this._access_token));
        if(value !== null){
            localStorage.setItem('access_token', this._access_token);
        } else {
            localStorage.removeItem('access_token');
        }
    }

    set user(value){
        // Set user common id
        if(value !== null){
            value = {
                ...value,
                ...{id: value['_id']}
            };
        }
        this._user = value;
        store.dispatch(authActions.storeUser(this._user));
        if(this._user !== null){
            localStorage.setItem('user', JSON.stringify(this._user));
        } else {
            localStorage.removeItem('user');
        }
    }

    get user(){
        this._user = store.getState().auth.user;
        if(!this._user && localStorage.getItem('user')){
            this._user = JSON.parse(localStorage.getItem('user'));
        }
        return this._user;
    }

    isGuest(){
        return this.access_token === null;
    }
}

export default new AuthService();