import UsersView from "../../views/users/users.view";
import VideosView from "../../views/videos/videos.view";
import TestView from "../../views/test/test.view";
import DebugConfig from "../../views/debug/debug-config.view";
import LogsView from "../../views/logs/logs.view";

const routes = [
    {
        path: "/users",
        component: UsersView,
        guest: false
    },
    {
        path: "/videos",
        component: VideosView,
        guest: false
    },
    {
        path: "/logs",
        component: LogsView,
        guest: false
    },
    {
        path: "/tests",
        component: TestView,
        guest: false
    },
    {
        path: "/debug-config",
        component: DebugConfig,
        guest: false
    }
];

export default routes;