import { alert as alertActions } from '../app/store/actions';
import store from '../app/store';

const push = (variant, message) => {
    store.dispatch(alertActions.push(variant, message));
};

const close = (index) => {
    store.dispatch(alertActions.close(index));
};

const flush = () => {
    store.dispatch(alertActions.flush());
};

export default {
    push,
    close,
    flush
}