import axios from 'axios';
import auth from '../services/auth.service';
import config from './config';
import history from "./history";

const instance = axios.create({
    baseURL: config.api
});

// Sign requests with jwt if exists
instance.interceptors.request.use(
    function (config) {
        // Inject jwt token to requests
        let token = auth.access_token;
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        // Prepare query string object
        // config.paramsSerializer = function (params) {
        //     return qs.stringify(params, {arrayFormat: 'brackets'});
        // };
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if(error.response.status === 401) {
            auth.logout();
            history.push('/login');
        }
        return Promise.reject(error);
    }
);

export default instance;