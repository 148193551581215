import {
    Box,
    Paper,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Button,
    Pagination,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@mui/material";
import React, {useEffect, useMemo, useRef, useState} from "react";
import api from '../../services/api.service';
import config from '../../app/config';
import SessionView from "./session.view";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs"


export default function LogsView() {

    const [page, setPage] = useState(1)
    const [totalCount, setTotalCount] = useState(0)
    const [pageSize, setPageSize] = useState(50)
    const [data, setData] = useState([])
    const [filterFrom, setFilterFrom] = useState(null)
    const [filterTo, setFilterTo] = useState(null)

    const fetch = async () => {
        const params = {
            page,
            pageSize
        }
        if (filterFrom) {
            params.filterFrom = dayjs(filterFrom).format('YYYY-MM-DD')
        }
        if (filterTo) {
            params.filterTo = dayjs(filterTo).format('YYYY-MM-DD')
        }
        const resData = (await api.logs.index(params))?.data
        setData(resData?.data || [])
        setTotalCount(resData?.totalCount || 0)
    }

    const pagesCount = useMemo(() => {
        return Math.ceil(totalCount / pageSize)
    }, [pageSize, totalCount])

    const sessions = useMemo(() => {
        return data.map(sess => {
            const startedAt = new Date(`${sess.started_at}`)
            sess.startedAtLocal = startedAt.toLocaleString()
            const finishedAt = new Date(`${sess.finished_at}`)
            sess.finishedAtLocal = finishedAt.toLocaleString()
            const now = new Date()
            sess.is_finished = (now.getTime() - finishedAt.getTime()) / 1000 > 30
            sess.steps.map(sessStep => {
                sessStep.createdAtLocal = (new Date(sessStep.created_at)).toLocaleString()
            })
            return sess
        })
    }, [data])

    const pollingInterval = useRef(null)

    useEffect(() => {
        setPage(1)
    }, [filterFrom, filterTo])

    useEffect(() => {
        return () => {
            if (pollingInterval.current) {
                clearInterval(pollingInterval.current)
            }
        }
    }, [])

    useEffect(() => {
        fetch()
        if (pollingInterval.current) {
            clearInterval(pollingInterval.current)
        }
        pollingInterval.current = setInterval(() => {
            fetch()
        }, 5000)
    }, [page, pageSize, filterFrom, filterTo])

    const onExport = (onePage = false) => async () => {
        let downloadUrl =`${config.api}logs/export`
        const params = onePage
            ? {
                page,
                pageSize
            }: {}
        if (filterFrom) {
            params.filterFrom = dayjs(filterFrom).format('YYYY-MM-DD')
        }
        if (filterTo) {
            params.filterTo = dayjs(filterTo).format('YYYY-MM-DD')
        }
        params.tz = Intl.DateTimeFormat().resolvedOptions().timeZone
        if (Object.keys(params).length) {
            downloadUrl += `?${new URLSearchParams(params).toString()}`
        }
        window.location = downloadUrl
    }

    return <>
        <Box
            my={3}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
        >
            <Typography variant="h4" component="h2">
                Logs
            </Typography>
            <Box>
                <Button
                    color="primary"
                    onClick={onExport(true)}
                >
                    Export Current Page
                </Button>
                <Button
                    style={{ marginLeft: '30px' }}
                    color="primary"
                    onClick={onExport()}
                >
                    Export Period
                </Button>
            </Box>
        </Box>
        <Box
            display="flex"
            justifyContent="space-between"
            mb={3}
        >
            <Box display="flex">
                <Box display="flex">
                    <DatePicker
                        label="From"
                        value={filterFrom}
                        onChange={setFilterFrom}
                    />
                </Box>
                <Box display="flex" ml={1}>
                    <DatePicker
                        label="To"
                        value={filterTo}
                        onChange={setFilterTo}
                    />
                </Box>
            </Box>
            <Box display="flex">
                <Box
                    display="flex"
                    alignItems="center"
                    ml={1}
                >
                    <Pagination
                        page={page}
                        onChange={(e, newPage) => {
                            setPage(newPage)
                        }}
                        count={pagesCount}
                        variant="outlined"
                    />
                </Box>
                <Box
                    display="flex"
                    ml={1}
                >
                    <FormControl fullWidth>
                        <InputLabel id="pageSize-select-label">
                            Page Size
                        </InputLabel>
                        <Select
                            labelId="pageSize-select-label"
                            id="demo-simple-select"
                            label="Page Size"
                            value={pageSize}
                            onChange={event => setPageSize(event.target.value)}
                            style={{ width: '80px' }}
                        >
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                            <MenuItem value={150}>150</MenuItem>
                            <MenuItem value={200}>200</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>
        </Box>
        <Box mb={3}>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="right">IP</TableCell>
                            <TableCell align="right">Device</TableCell>
                            <TableCell align="right">Avg. Compression</TableCell>
                            <TableCell>Video/Preset</TableCell>
                            <TableCell align="right">Start Time</TableCell>
                            <TableCell align="right">End Time</TableCell>
                            <TableCell align="right">View(sec)</TableCell>
                            <TableCell align="right">View(min)</TableCell>
                            <TableCell align="right">Orig Run(min)</TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    {sessions.map((sess) => (
                        <SessionView
                            session={sess}
                            key={sess.session_id}
                        />
                    ))}
                </Table>
            </TableContainer>
        </Box>
    </>
}