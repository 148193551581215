const push = (variant, message) => ({
    type: 'PUSH_ALERT',
    variant: variant,
    message: message
});

const close = index => ({
    type: 'CLOSE_ALERT',
    index: index,
});

const flush = () => ({
    type: 'FLUSH_ALERTS'
});

export default {
    push,
    close,
    flush
}