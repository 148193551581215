import React from "react";
import { AppBar, Toolbar, Button, IconButton, Box } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import history from '../../app/history';
import authService from '../../services/auth.service';
import { connect } from 'react-redux';
import LayoutAlert from './Alert';

class LayoutHeader extends React.Component {

    constructor(props) {
        super(props);
        this.onLogout = this.onLogout.bind(this);
    }

    onLogout() {
        authService.logout();
        history.push('/login');
    }

    render() {
        let authControls;
        if (!this.props.access_token) {
            authControls = <Button onClick={ () => history.push('/login') }  color="inherit">Login</Button>;
        } else {
            authControls = <Button onClick={this.onLogout}  color="inherit">Logout</Button>
        }

        return (
            <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="menu" size="large">

                    </IconButton>
                    <Box flexGrow={1} display="flex" justifyContent="flex-start">
                        <Button onClick={ () => history.push('/videos') } color="inherit">Videos</Button>
                        <Button onClick={ () => history.push('/users') } color="inherit">Users</Button>
                        <Button onClick={ () => history.push('/logs') } color="inherit">Logs</Button>
                        <Button onClick={ () => history.push('/tests') } color="inherit">Feature Tests</Button>
                        <Button onClick={ () => history.push('/debug-config') } color="inherit">Debug</Button>
                    </Box>

                    {authControls}

                    <IconButton
                        edge="end"
                        aria-label="account of current user"
                        //aria-controls={menuId}
                        aria-haspopup="true"
                        //onClick={handleProfileMenuOpen}
                        color="inherit"
                        size="large">
                        <AccountCircle />
                    </IconButton>
                </Toolbar>

                <LayoutAlert/>
            </AppBar>
        );
    }
}

const mapStateToProps = function(state){
    return {
        access_token: state.auth.access_token
    }
};

export default connect(mapStateToProps)(LayoutHeader);