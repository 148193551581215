import React from "react";
import {
    Box, Grid, Typography, Icon, Button, ButtonGroup,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody
} from '@mui/material';
import {users as usersService} from '../../services/api.service';
import DeleteConfirm from "../../components/DeleteConfirm";
import alert from "../../services/alert.service";
import auth from "../../services/auth.service";
import UserForm from "./user.form";

export default class UsersView extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            data: [],
            pendingDelete: null,
            pendingForm: null
        };
        this.setPendingDeleteID = this.setPendingDeleteID.bind(this);
        this.setPendingFormID = this.setPendingFormID.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onFormSave = this.onFormSave.bind(this);
        this.onFormClose = this.onFormClose.bind(this);
    }

    setData(data){
        this.setState(state => ({
            data: data.map(row => {
                return {id: row['_id'], ...row}
            })
        }));
    }

    componentDidMount() {
        this.loadItems();
    }

    loadItems() {
        usersService.index()
            .then((res) => {
                this.setData(res.data);
            });
    }

    actionsColumn(id){
        return (<ButtonGroup variant="text" color="primary" aria-label="text primary button group">
            <Button onClick={() => this.setPendingFormID(id)}>
                <Icon color="secondary">edit</Icon>
            </Button>
            { auth.user.id !== id &&
                <Button onClick={() => {
                    this.setPendingDeleteID(id)
                }}>
                    <Icon color="secondary">delete</Icon>
                </Button>
            }
        </ButtonGroup>);
    }

    setPendingDeleteID(id){
        this.setState({pendingDelete: id});
    }

    setPendingFormID(id){
        this.setState({pendingForm: id});
    }

    onDelete(){
        return usersService.destroy(this.state.pendingDelete)
            .then((res) => {
                alert.push('success', 'User successfully deleted!');
                this.loadItems();
                this.setPendingDeleteID(null);
            });
    }

    onFormClose(){
        this.setPendingFormID(null);
    }

    onFormSave(pSave){
        pSave.then((res) => {
            this.setPendingFormID(null);
            this.loadItems();
        })
    }

    render() {
        return (
            <React.Fragment>
                <DeleteConfirm open={!!this.state.pendingDelete} onCancel={()=>this.setPendingDeleteID(null)} onConfirm={this.onDelete}/>
                <UserForm open={this.state.pendingForm !== null} id={this.state.pendingForm} onFormClose={this.onFormClose} onFormSave={this.onFormSave}/>

                <Grid item xs={12}>

                    <Box align="left">
                        <Box my={3}>
                            <Typography variant="h4" component="h2">
                                Users
                            </Typography>
                        </Box>

                        <Box my={3}>
                            <Button color="primary" onClick={() => this.setPendingFormID(-1) }>Create</Button>
                        </Box>

                        <Box height={400} width={'100%'}  align={'left'}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.data.map((row) => (
                                            <TableRow key={row._id}>
                                                <TableCell>
                                                    {row.name}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.email}
                                                </TableCell>
                                                <TableCell>
                                                    {this.actionsColumn(row._id)}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Grid>
            </React.Fragment>
        )
    }
}