import React from 'react';
import { styled } from '@mui/material/styles';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Dialog, DialogActions, DialogContent, DialogTitle, Paper, Button} from "@mui/material";
const PREFIX = 'status-check-dialog';

const classes = {
    error: `${PREFIX}-error`,
    success: `${PREFIX}-success`
};

const StyledDialog = styled(Dialog)({
    [`& .${classes.error}`]: {
        background: '#e57373'
    },
    [`& .${classes.success}`]: {
        background: '#81c784'
    }
});

export default function DeleteConfirm ({onClose, open, data, test}) {



    return !!data && (
        <StyledDialog
            open={open}
            onClose={() => { onClose() }}
            maxWidth={'xl'}
        >
            <DialogTitle>{ `${test.name}", Thld: ${test.thld}` }</DialogTitle>
            <DialogContent>
                <TableContainer component={Paper} style={{maxHeight: '80vh'}}>
                    <Table style={{minWidth: 640}} stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Segment #</TableCell>
                                <TableCell>Expected</TableCell>
                                <TableCell>In Fact</TableCell>
                                <TableCell>Test Status</TableCell>
                                <TableCell>Meta</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((segment, iRow) => (
                                <TableRow
                                    key={`row-${iRow}`}
                                >
                                    <TableCell component="th" scope="row"
                                       className={`${!!segment.testResult? classes.success: classes.error}`}
                                    >
                                        {segment.id}
                                    </TableCell>
                                    <TableCell
                                        align={'center'}
                                        className={`${!!segment.expectToBeIncluded? classes.success: classes.error}`}
                                    >
                                        {segment.expectToBeIncluded? 'YES': 'NO'}
                                    </TableCell>
                                    <TableCell
                                        align={'center'}
                                        className={`${!!segment.isIncluded? classes.success: classes.error}`}
                                    >
                                        {segment.isIncluded? 'YES': 'NO'}
                                    </TableCell>
                                    <TableCell
                                        align={'center'}
                                        className={`${!!segment.testResult? classes.success: classes.error}`}
                                    >
                                        {segment.testResult? 'YES': 'NO'}
                                    </TableCell>
                                    <TableCell
                                        className={`${!!segment.testResult? classes.success: classes.error}`}
                                    >
                                        <p>Significance: {segment.significance}</p>
                                        <p>Type: {segment.type}</p>
                                        {
                                            Object.keys(segment.meta).map(key => (
                                                segment.meta.filterFeatureApplied === key
                                                    ? <p key={key}>
                                                        <b>{key}: {!!segment.meta[key]? 'true': 'false'}</b>
                                                    </p>
                                                    : <p key={key}>
                                                        {key}: {!!segment.meta[key]? 'true': 'false'}
                                                    </p>
                                            ))
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { onClose() }} color="secondary" autoFocus>
                    Close
                </Button>
            </DialogActions>
        </StyledDialog>
    );
}