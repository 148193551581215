import React, {useEffect, useMemo, useState} from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import {Box, Button, IconButton, TextField, Typography} from "@mui/material";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {Add, Cancel, Check, CloudUpload, Delete, InfoOutlined, PlayArrow, Save} from "@mui/icons-material";
import {tests as testsService} from '../../services/api.service';
import DeleteConfirm from "../../components/DeleteConfirm";
import StatusCheckDialog from './status-check-dialog';

const PREFIX = 'test';

const classes = {
    table: `${PREFIX}-table`,
    testError: `${PREFIX}-testError`,
    testSuccess: `${PREFIX}-testSuccess`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
    [`& .${classes.table}`]: {
        minWidth: 650,
    },
    [`& .${classes.testError}`]: {
        border: '1px solid #e57373'
    },
    [`& .${classes.testSuccess}`]: {
        border: '1px solid #81c784'
    }
});

const NEW_TEST_CASE = {
    name: '',
    segments_file: null,
    included: '',
    thld: 50,
    status: null
}

export default function TestView(props) {


    const [data, setData] = useState([])

    useEffect(() => {
        fetchTests()
    }, [])

    const [pendingDeleteIndex, setPendingDeleteIndex] = useState(null)
    const [pendingStatusCheckIndex, setPendingStatusCheckIndex] = useState(null)

    const fetchTests = () => {
        testsService.index().then(response => {
            setData(response.data);
        })
    }

    const link = (iRow, field) => event=> {
        const value = (field.indexOf('_file') === -1)?
            event.target.value
            :event.target.files[0];
        const newData = [...data]
        newData[iRow][field] = value
        setData(newData)
    }

    const onAdd = () => {
        setData([
            ...data,
            {...NEW_TEST_CASE}
        ])
    }

    const onDeleteConfirm = () => {
        const item = data[pendingDeleteIndex]
        if(!!item._id) {
            testsService.destroy(item._id)
        }
        const newData = [...data]
        newData.splice(pendingDeleteIndex, 1)
        setData(newData)
        setPendingDeleteIndex(null)
    }

    const onDelete = index => () => {
        setPendingDeleteIndex(index)
    }

    const onSave = async () => {
        await Promise.all(
            data.map(async (test, iTest) => await saveItem(test, iTest))
        )
        await fetchTests()
    }

    const saveItem = async (test, iTest) => {
        try {
            let newData
            if( !test._id ) {
                newData = (await testsService.store(test))?.data
            } else {
                newData = (await testsService.update(test._id, test)).data
            }
            setData([
                ...data.map((item, iItem) =>
                    iItem === iTest? newData: item
                )
            ])

        } catch (err) {
            setData([
                ...data.map((item, iItem) =>
                    iItem === iTest?
                        {
                            ...item,
                            error: true
                        }: item
                )
            ])
        }
    }

    const onTest = iTest => async () => {
        await saveItem(data[iTest], iTest)
        const test = data[iTest]
        const {data: testResult} = await testsService.process(test._id)
        console.log(testResult)
        setData([
            ...data.map((item, iItem) =>
                iItem === iTest?
                    {
                        ...item,
                        testResult
                    }: item
            )
        ])
    }

    const onTestAll = async () => {
        for(const iTest in data) {
            await onTest(iTest)()
        }
    }

    const onShowStatusCheck = iTest => () => {
        setPendingStatusCheckIndex(iTest)
    }

    const pendingStatusCheckData = useMemo(() => {
        return pendingStatusCheckIndex !== null
            && !!data[pendingStatusCheckIndex]?.testResult
            && Object.values(data[pendingStatusCheckIndex]?.testResult?.segmentsData)
    }, [pendingStatusCheckIndex])

    const pendingTest = useMemo(() => data?.[pendingStatusCheckIndex], [pendingStatusCheckIndex])

    return (
        <Root>
            <Box display={'flex'} flexDirection={'column'}>
                <Box display={'flex'} mt={3}>
                    <Typography variant={'h3'}>
                        Feature tests
                    </Typography>
                </Box>
                <Box display={'flex'} mt={3}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Feature Name</TableCell>
                                    <TableCell>Segments File (*.json)</TableCell>
                                    <TableCell>Threshold</TableCell>
                                    <TableCell>Included IDs (1,2,..)</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row, iRow) => (
                                    <TableRow
                                        key={`row-${iRow}`}
                                        className={`${!!row.testResult && !!row.testResult.status && classes.testSuccess} ${!!row.testResult && !row.testResult.status && classes.testError}`}
                                    >
                                        <TableCell component="th" scope="row">
                                            <TextField
                                                value={row.name}
                                                onChange={link(iRow, 'name')}
                                                fullWidth
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <label>
                                                <input onChange={link(iRow, 'segments_file')} style={{ display: 'none' }} type="file"/>
                                                <Button variant="contained" component="span" color="primary" startIcon={<CloudUpload />}>
                                                    { row.segments_file?.name || row.segments_file?.originalname || 'Upload'}
                                                </Button>
                                            </label>
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                value={row.thld}
                                                onChange={link(iRow, 'thld')}
                                                fullWidth
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                value={row.included}
                                                onChange={link(iRow, 'included')}
                                                fullWidth
                                                multiline
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {!!row.testResult &&
                                                <Box display={'flex'} alignItems={'center'}>
                                                    <Box display={'flex'}>
                                                        {!!row.testResult.status &&
                                                        <Check style={{color: 'green'}}/>
                                                        }
                                                        {!row.testResult.status &&
                                                        <Cancel style={{color: '#f44336'}}/>
                                                        }
                                                    </Box>
                                                    <Box ml={1} display={'flex'} style={{cursor: 'pointer'}} onClick={onShowStatusCheck(iRow)}>
                                                        <InfoOutlined/>
                                                    </Box>
                                                </Box>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <Box display={'flex'} flexWrap={'no-wrap'}>
                                                <Box display={'flex'}>
                                                    <IconButton
                                                        onClick={onDelete(iRow)}
                                                        variant="contained"
                                                        component="span"
                                                        color="secondary"
                                                        size="large">
                                                        <Delete />
                                                    </IconButton>
                                                </Box>
                                                <Box display={'flex'}>
                                                    <Button onClick={onTest(iRow)} variant="contained" component="span" color="primary" startIcon={<PlayArrow />}>
                                                        Test
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box display={'flex'} justifyContent={'space-between'} mt={3}>
                    <Box display={'flex'}>
                        <Button onClick={onAdd} variant="contained" component="span" color="primary" startIcon={<Add />}>
                            Add Test Case
                        </Button>
                    </Box>
                    <Box display={'flex'}>
                        <Box display={'flex'}>
                            <Button onClick={onSave} variant="contained" component="span" color="primary" startIcon={<Save />}>
                                Save
                            </Button>
                        </Box>
                        {false &&
                        <Box display={'flex'} ml={1}>
                            <Button onClick={onTestAll} variant="contained" component="span" color="primary">
                                Test All
                            </Button>
                        </Box>
                        }
                    </Box>
                </Box>
            </Box>
            <DeleteConfirm open={pendingDeleteIndex !== null} onCancel={() => setPendingDeleteIndex(null)} onConfirm={onDeleteConfirm}/>
            <StatusCheckDialog
                data={pendingStatusCheckData}
                test={pendingTest}
                open={pendingStatusCheckIndex !== null}
                onClose={() => setPendingStatusCheckIndex(null)}/>
        </Root>
    );
}